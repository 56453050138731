import React from "react"
import styled from "styled-components"

import Layout from "../components/structure/layout"
import { Container } from "../components/styled/container"
import { Hero } from "../components/styled/hero"
import { Colors } from "../components/theme"

const PageContent = styled.div`
  font-family: "Merriweather";
  font-size: 17px;
  line-height: 35px;
  margin: auto;
`

const Form = styled.form`
  input,
  textarea {
    border: 2px solid ${Colors.scdsDark};
    border-radius: 3px;
    padding: 10px;
  }

  label {
    display: flex;
    flex-direction: column;
  }

  input[type="submit"] {
    background: ${Colors.scdsMain};
    border: none;
    padding: 15px 30px;
    margin-top: 20px;
    color: white;
    font-weight: 600;
    font-family: "Inter";
    text-transform: uppercase;
    cursor: pointer;
  }
`

const SobreNosotros = () => {
  return (
    <Layout>
      <Hero>
        <Container>
          <h1>Contacto</h1>
          <p>
            En los gráficos estacionales se muestra la evolución de las
            variables meteorológicas a lo largo de una estación climatológica.
          </p>
        </Container>
      </Hero>
      <Container>
        <PageContent>
          <p>
            Juan Carlos García Codron, profesor de Geografía Física de la
            Universidad de Cantabria nos recuerda que existen 5 millones de
            especies en el mundo, aunque parece que aún hay muchas desconocidas
            por la ciencia, y que "necesitamos conservar la biodiversidad,
            porque cada una de las especies interactúan entre sí e interactúan
            con su entorno inanimado. Estas interacciones dan lugar a un
            equilibrio que permite la existencia de la vida tal y como es hoy.
            Es un equilibrio al que se ha llegado después de 4.000 millones de
            años, muy complejo".
          </p>
          <h2>
            La UC se une al Día Mundial del Medio Ambiente, dedicado a la
            conservación de la biodiversidad
          </h2>
          <Form action="" id="form-contact">
            <label>
              Nombre
              <input type="text" placeholder="Escribe tu nombre y apellidos" />
            </label>
            <label>
              Asunto
              <input type="text" placeholder="Escribe tu asunto" />
            </label>
            <label>
              Observaciones
              <textarea
                cols="50"
                rows="10"
                placeholder="Escribe aquí tus observaciones"
              />
            </label>
            <input type="submit" value="Enviar" />
          </Form>
        </PageContent>
      </Container>
    </Layout>
  )
}

export default SobreNosotros
